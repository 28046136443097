import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import VideoPlayer from './VideoPlayer'
//import VimeoPlayer from "@vimeo/player";
import {
  faAlignRight,
    faX,
  } from "@fortawesome/free-solid-svg-icons"
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby";

const Modal = ({ isOpen, onClose, title, description, videoId, videoData, ul }) => {
  const [player, setPlayer] = useState(null);

  const handleModalClose = (e) => {
    onClose();
    e.preventDefault();
  };

  const handleModalOpen = () => {

  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      onAfterOpen={handleModalOpen}
    >
      <div className="modal-content"  style={{maxWidth:`1000px`, margin : `0 auto`, position: `relative`}}>
        <button className="close-button" style={{position: `absolute`, right: `0px`}} onClick={handleModalClose}><FontAwesomeIcon icon={faX} /></button>
        <div className="modal-body">

          <VideoPlayer
            embedId={videoId}
            title={title}
            data={videoData}
            className="full"
           />
           <div className="row lg_mt-2 mt-2" style={{maxWidth:`1000px`}}>
           <div className="columns">
           <h4 class="tc-viridian jost-med" id="vid-title">{title}</h4>
           <p>{description}</p>
           {ul &&
            <ul className="ul--dotted">
              {ul.map(item => (
                <li>{item}</li>
              ))}
            </ul>
           }
           </div>
           </div>
           <div className="row align-right" style={{maxWidth:`1000px`}}>
            <div className="columns shrink lg_mt-2 mt-2">
            <a href="#" style={{fontWeight: "600", textDecoration: "underline"}} className="tc-barney" onClick={(e)=>handleModalClose(e)}>Go back to page</a></div>
           </div>
        </div>
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  videoId: PropTypes.number.isRequired,
};

export default Modal;
