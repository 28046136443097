import introducingThePanel from "../../images/video-thumbnails/introducingThePanel.jpg"
import dealingWithGuilt from "../../images/video-thumbnails/dealingWithGuilt.jpg"
import facingTheDiagnosis from "../../images/video-thumbnails/facingTheDiagnosis.jpg"
import allowingVulnerability from "../../images/video-thumbnails/allowingVulnerability.jpg"
import findingStrength from "../../images/video-thumbnails/findingStrength.jpg"
import findingJoy from "../../images/video-thumbnails/findingJoy.jpg"
import learningToCope from "../../images/video-thumbnails/learningToCope.jpg"
import resources from "../../images/video-thumbnails/resources.jpg"
import psychologicalStandards from "../../images/video-thumbnails/psychologicalStandards.jpg"
import finances from "../../images/video-thumbnails/finances.jpg"
import lifeAfter from "../../images/video-thumbnails/lifeAfter.jpg"
import grief from "../../images/video-thumbnails/grief.jpg"
import regoaling from "../../images/video-thumbnails/regoaling.jpg"
import siblingImpact from "../../images/video-thumbnails/siblingImpact.jpg"
import perspectiveChange from "../../images/video-thumbnails/perspectiveChange.jpg"
import hope from "../../images/video-thumbnails/hope.jpg"

export const videos = [
    {
        title: "Introducing the Panel",
        embedId: "vMEEW2gFrb0?si=SXUknhGZ_HCNhs7C",
        imageSrc: introducingThePanel,
        description: "Meet the panel as they explore the topic of mental health and psychosocial well-being for families dealing with childhood cancer.",
    },
    {
        title: "Dealing With the Initial Guilt",
        embedId: "fHFM0dWh0p4?si=HdYzsBu7cezhJ8p4",
        imageSrc: dealingWithGuilt,
        description: "The panel discusses feelings of guilt associated with receiving a childhood cancer diagnosis and the emotional impact it had.",
    },
    {
        title: "Facing the Diagnosis",
        embedId: "Jme34U-7rDY?si=PtW9iwVaM-kaHAyi",
        imageSrc: facingTheDiagnosis,
        description: "Wendy talks about the emotions she experienced when her son, Evan, was diagnosed, and the determination she found within herself to fight for her family.",
    },
    {
        title: "Allowing Yourself to Be Vulnerable",
        embedId: "p19yMUreqbk?si=BDyRMEk6SI80KUj9",
        imageSrc: allowingVulnerability,
        description: "Discover the importance of fostering an open environment that encourages you and your family to express your feelings and emotions.",
    },
    {
        title: "Finding the Strength to Fight",
        embedId: "djyWjSI0TJw?si=IkmJxoPloKwwNXey",
        imageSrc: findingStrength,
        description: "Devon Still describes summoning the courage to stand up and fight for his daughter’s life amid overwhelming feelings of fear and uncertainty.",
    },
    {
        title: "Finding Joy and Celebrating the Small Victories",
        embedId: "djyWjSI0TJw?si=yDhm1LRrlFMwn43X",
        imageSrc: findingJoy,
        description: "Learn how finding joy in small ways can create a more positive environment.",
    },
    {
        title: "Learning to Cope Together",
        embedId: "IeIey8TEc2k?si=fPP_3eT78KCkiTca",
        imageSrc: learningToCope,
        description: "Explore dyadic coping and how this approach helps to provide a better support system for the entire family.",
    },
    {
        title: "Resources You Can Tap Into",
        embedId: "CMPYDOwGW1Y?si=XyHGbnkJyTYgl_q6",
        imageSrc: resources,
        description: "Watch as cancer survivors highlight the resources available at hospitals that provided them with support and comfort.",
    },
    {
        title: "Psychological Standards of Care",
        embedId: "AX37Q7jSnZk?si=AzMCRnO9V9xrirna",
        imageSrc: psychologicalStandards,
        description: "Explore the psychosocial standards of care and why it’s important to not only address the child’s needs, but also those of the parents.",
    },
    {
        title: "Burden of Finances",
        embedId: "uyFEodl7Xh4?si=Rx9rswJjSg7YeMJS",
        imageSrc: finances,
        description: "Watch the panelists discuss the financial challenges families face when a child is diagnosed with cancer and where to look for help.",
    },
    {
        title: "Life After Treatment",
        embedId: "LP7k7QJ-MRY?si=ElaCuJIPIzv1NJym",
        imageSrc: lifeAfter,
        description: "Childhood cancer survivors talk about what it’s like to transition back to “normal life” after cancer treatment.",
    },
    {
        title: "Grief and Loss",
        embedId: "tNpDOoHO74M?si=XucmSuzM5f7TcKt7",
        imageSrc: grief,
        description: "Watch as the panelists share their personal experiences with grief and loss and highlight the importance of supporting each other through the ups and downs.",
    },
    {
        title: "How Regoaling Can Reboot Your Life",
        embedId: "nwDMtd7th5I?si=gMT7dvxFMjR-rssF",
        imageSrc: regoaling,
        description: "Learn about the important skill of regoaling and how it can help both parents and children navigate the childhood cancer journey.",
    },
    {
        title: "The Impact On Siblings",
        embedId: "-wJhODhAK5Q?si=Ws72xh462pnkH9sh",
        imageSrc: siblingImpact,
        description: "Hear our panelists discuss the impact that childhood cancer can have on siblings and what steps can be taken to make sure the needs of siblings are met.",
    },
    {
        title: "How Cancer Changes Your Perspective",
        embedId: "iixCBIYlpJI?si=KVlD8znYWRQahfwM",
        imageSrc: perspectiveChange,
        description: "Find out about the surprising way the childhood cancer journey changed our panelists’ way of relating to others.",
    },
    {
        title: "Parting Words of Hope and Inspiration",
        embedId: "89GTT-o33UQ?si=CLxkavQwA3l7tnhP",
        imageSrc: hope,
        description: "Hear from each panelist as they reflect on their personal experience with cancer and offer their parting thoughts.",
    },

]
